import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 80,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Poppins, sans-serif",
    secondary: "TiemposHeadline",
    tertiary: "Messina Sans, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#15372C",
      light: "#FFFFFF",
      dark: "#222222",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#F1F8F4",
      light: "#D1D1D1",
      dark: "#000000",
    },
    tertiary: {
      main: "#E9E9E9",
      light: "#C9C9C9",
      dark: "#B1DAA9",
      other: "#EBF5EF",
    },
    text: {
      primary: "#15372C",
      secondary: "#D1D1D1",
      content: "#5F5F5F",
      tertiary: "#F9F9F9",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
      other: "#008561",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"DM Sans", "Open Sans", "Arial", sans-serif`,
  },
})

export default theme
